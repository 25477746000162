.dropdown_menu .dropdown_item {
    transform-origin: top center;
}

.dropdown_menu ul .dropdown_item-1 {
    animation: scaleZ 100ms ease-in-out forwards;
}
.dropdown_menu ul .dropdown_item-2 {
    animation: scaleZ 200ms ease-in-out forwards;
}
.dropdown_menu ul .dropdown_item-3 {
    animation: scaleZ 300ms ease-in-out forwards;
}  
.dropdown_menu ul .dropdown_item-4 {
    animation: scaleZ 400ms ease-in-out forwards;
}
.dropdown_menu ul .dropdown_item-5 {
    animation: scaleZ 500ms ease-in-out forwards;
}

.dropdown_menu .dropdown_item {
    display: none;
}
.dropdown_menu .dropdown_item_active {
    display: block;
}

@keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    
    80% {
      transform: scale(1.07);
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
    }
}
       